import { render, staticRenderFns } from "./LeatherWeekend.vue?vue&type=template&id=285b8e6a&scoped=true&"
import script from "./LeatherWeekend.vue?vue&type=script&lang=js&"
export * from "./LeatherWeekend.vue?vue&type=script&lang=js&"
import style0 from "./LeatherWeekend.vue?vue&type=style&index=0&id=285b8e6a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285b8e6a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardText,VCardTitle,VFlex,VIcon,VImg,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileTitle})
