<template>
  <v-layout row wrap mb-3>
    <v-layout row>
      <!-- comment out after MLW is over -->
      <v-flex xs3 sm2 md1 px-0 mx-0>
        <v-card
          :class="{
            'fixed-large': $vuetify.breakpoint.lgAndUp,
            'fixed-small': $vuetify.breakpoint.mdAndDown,
            'fixed-xs': $vuetify.breakpoint.xs,
          }"
        >
          <v-list
            dense
            class="py-1 grey darken-4"
            two-line
            router
            :to="item.link"
            v-for="item in items"
            :key="item.title"
          >
            <v-list-tile router :to="item.link" exact>
              <v-list-tile-content>
                <v-list-tile-action class="justify-space-between">
                  <v-icon class="justify-space-between" right>{{
                    item.icon
                  }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="text-xs-center pb-4">{{
                  item.title
                }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <!-- comment out after MLW is over -->

      <v-flex pb-4 mb-4 pt-0 mt-0 px-0 ml-2 xs12>
        <v-card min-width="250px" max-width="600px" pb-4 mb-4>
          <v-card-title class="headline black justify-center py-1">
            MLW {{ year }}
          </v-card-title>
          <v-img src="/assets/mlw24-date.jpg"> </v-img>
          <v-card-text class="text-xs-center text-sm-left">
            <div class="caption grey--text">
              <!-- MLW is the biggest event in our calendar, held annually in October
              and attracting attendees from around the world.
              <strong>The {{ year }} weekend</strong> is not to be missed! For
              events info and to purchase tickets please click the links on the
              left. -->
              <p>
                Manchester Leather Weekend is the biggest event in our calendar
                and we are looking forward to welcoming the leather community
                from around the world for an event that is not to be missed.
              </p>
              <p>
                The next Manchester Leather Weekend will be on the 4th-7th of October 2024. We have prepared an exciting weekend for you in Manchester and we believe there is something for everyone this year. Tickets are limited so buy yours now to avoid disappointment!
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<style scoped>
.v-list__tile__content {
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}

.fixed-xs {
  position: fixed;
  left: 0px;
  z-index: 1;
  opacity: 0.75;
}

.fixed-small {
  position: fixed;
  left: 0px;
  z-index: 1;
}

.fixed-large {
  position: fixed;
  z-index: 1;
}
</style>
<script>
export default {
  metaInfo: {
    titleTemplate: `%s | Leather Weekend`,
    link: [
      {
        rel: "canonical",
        href: "https://www.manchesterleathermen.com/weekend",
      },
    ],
    meta: [
      { itemprop: "name", content: "MLM Weekend ← Manchester Leathermen" },
      {
        itemprop: "description",
        content:
          "Manchester Leather Weekend is back bigger and better than ever before.",
      },
    ],
  },
  data: () => ({
    items: [
      { title: "Info", icon: "mdi-information-outline", link: "/weekend" },
      {
        title: "Events",
        icon: "mdi-calendar-star",
        link: "/weekend/events",
      },
      {
        title: "Tickets",
        icon: "mdi-ticket-account",
        link: "/weekend/tickets",
      },
    ],
  }),
  computed: {
    year: function () {
      const d = new Date();
      const m = d.getMonth();
      let y = d.getFullYear();
      if (m < 9) {
        return y;
      } else if (m === 9) {
        const d = new Date();
        const date = d.getDate();
        const day = d.getDay();
        const weekOfMonth = Math.ceil((date - 1 - day) / 7);
        if (weekOfMonth == 1) {
          return y + 1;
        } else {
          return y + 1;
        }
      } else {
        return y + 1;
      }
    },
  },
};
</script>
